<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="星级" prop="starLevel" >
        <a-select v-model="form.starLevel">
          <a-select-option :value="1">
            一星
          </a-select-option>
          <a-select-option :value="2">
            二星
          </a-select-option>
          <a-select-option :value="3">
            三星
          </a-select-option>
          <a-select-option :value="4">
            四星
          </a-select-option>
          <a-select-option :value="5">
            五星
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getStoreCertification, addStoreCertification, updateStoreCertification } from '@/api/platform/storeCertification'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        merchantType: null,

        certificationType: null,

        legalRealName: null,

        legalMobile: null,

        legalCard: null,

        portraitPlane: null,

        emblemPlane: null,

        businessLicense: null,

        companyName: null,

        socialCredit: null,

        businessScope: null,

        userId: null,

        applicationStatus: 0,

        refusalCause: null,

        storeName: null,

        storeLogo: null,

        storeBackground: null,

        typeId: null,

        classId: null,

        integralBail: null,

        integralServe: null,

        storeCountry: null,

        storeProvinceName: null,

        storeCityName: null,

        storeCountyName: null,

        storeDetailedAddress: null,

        storeLongitude: null,

        storeDimensionality: null,

        companyCountry: null,

        companyProvinceName: null,

        companyCityName: null,

        companyCountyName: null,

        companyDetailedAddress: null,

        companyLongitude: null,

        companyDimensionality: null,

        companyAddress: null,

        storeDetail: null,

        openStartTime: null,

        openEndTime: null,

        storeCall: null,

        storePrincipal: null,

        principalCall: null,

        doorPicture: null,

        starLevel: null,

        createTime: null,

        remark: null,

        collectionNum: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        merchantType: null,
        certificationType: null,
        legalRealName: null,
        legalMobile: null,
        legalCard: null,
        portraitPlane: null,
        emblemPlane: null,
        businessLicense: null,
        companyName: null,
        socialCredit: null,
        businessScope: null,
        userId: null,
        applicationStatus: 0,
        refusalCause: null,
        storeName: null,
        storeLogo: null,
        storeBackground: null,
        typeId: null,
        classId: null,
        integralBail: null,
        integralServe: null,
        storeCountry: null,
        storeProvinceName: null,
        storeCityName: null,
        storeCountyName: null,
        storeDetailedAddress: null,
        storeLongitude: null,
        storeDimensionality: null,
        companyCountry: null,
        companyProvinceName: null,
        companyCityName: null,
        companyCountyName: null,
        companyDetailedAddress: null,
        companyLongitude: null,
        companyDimensionality: null,
        companyAddress: null,
        storeDetail: null,
        openStartTime: null,
        openEndTime: null,
        storeCall: null,
        storePrincipal: null,
        principalCall: null,
        doorPicture: null,
        starLevel: null,
        createTime: null,
        remark: null,
        collectionNum: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getStoreCertification({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '设置星级'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateStoreCertification(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addStoreCertification(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
